import { useState, createContext, useContext, type ReactElement, type ChangeEvent } from 'react';
import screens, { type Screen } from '../common/screens';
import { useMapElement } from './MapElementProvider';
import type { Wait } from '../../../../types/graph';
import type { ValueElementIdReferenceAPI } from '../../../../types';
import { addDays, addHours, addMinutes, addSeconds, differenceInDays } from 'date-fns';
import { WAIT_BOUNDARIES } from '../common/wait/constants';
import translations from '../../../../translations';
import { isNullOrEmpty } from '../../../../utils/guard';

export type OnTimezoneChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => void;
export type OnDaysChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => void;
export type OnHoursChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => void;
export type OnMinutesChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => void;
export type OnSecondsChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => void;
export type OnRelativeChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => void;
export type OnAbsoluteChange = (date: string) => void;
export type OnValueChanged = (value: ValueElementIdReferenceAPI | null) => void;
export type OnUpdateName = (developerName: string) => void;
export type OnOutcomeChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => void;
export type SetHasSubmitted = (state: boolean) => void;
export type SetDefaultWait = () => void;
export type OnReturnToDefaultScreen = () => void;
export type OnApplyWait = (index: number) => void;
export type OnCreateWait = () => void;
export type OnEditWait = (wait: Wait, index: number) => void;
export type OnDeleteWait = (index: number) => void;
export type OnSelectDeveloperName = (developerName: string) => void;
export type OnSelectWaitType = (waitType: string) => void;
export type SetIsMapElement = (state: boolean) => void;
export type OnWaitTypeChange = () => void;
export type OnHeadlessChange = (args: {
    event: ChangeEvent<HTMLInputElement>;
    isOn: boolean;
}) => void;

interface Props {
    defaultScreen: Screen;
    children?: ReactElement;
}

interface WaitTestProps {
    hasChanged: boolean;
    hasChangedComplete: boolean;
    waitType: string;
}

interface WaitToEditState {
    index: null | number;
    wait: null | Wait;
    testProps?: WaitTestProps;
}

interface WaitContext {
    waitToEdit: WaitToEditState;
    onCreateWait: OnCreateWait;
    onEditWait: OnEditWait;
    onDeleteWait: OnDeleteWait;
    onSelectDeveloperName: OnSelectDeveloperName;
    onSelectWaitType: OnSelectWaitType;
    onApplyWait: OnApplyWait;
    onReturnToDefaultScreen: OnReturnToDefaultScreen;
    hasSubmitted: boolean;
    setHasSubmitted: SetHasSubmitted;
    setDefaultWait: SetDefaultWait;
    isValidWait: () => boolean;
    onTimezoneChange: OnTimezoneChange;
    onDaysChange: OnDaysChange;
    onHoursChange: OnHoursChange;
    onMinutesChange: OnMinutesChange;
    onSecondsChange: OnSecondsChange;
    onRelativeChange: OnRelativeChange;
    onAbsoluteChange: OnAbsoluteChange;
    onValueChanged: OnValueChanged;
    onUpdateName: OnUpdateName;
    onOutcomeChange: OnOutcomeChange;
    setIsMapElement: SetIsMapElement;
    onWaitTypeChange: OnWaitTypeChange;
    onHeadlessChange: OnHeadlessChange;
    initialWait: Wait;
    isNameValid: () => boolean;
    isMaximumValid: () => boolean;
    isMinimumValid: () => boolean;
    isValidHeadlessWait: () => boolean;
    noNegative: () => boolean;
    allPopulated: () => boolean;
    isValidRelativeTime: () => boolean;
    isValidAbsoluteTime: () => boolean;
    isAbsolutePopulated: () => boolean | null | undefined;
    isValidValue: () => boolean;
    isValidValueContentType: () => boolean | null | undefined;
    isValidPageWait: () => boolean;
}

const Context = createContext<WaitContext | undefined>(undefined);

const WaitProvider = (props: Props) => {
    const { mapElement, setMapElement, onSwitchScreen } = useMapElement();

    const initialWait: Wait = {
        developerName: translations.WAIT_default_name,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        relative: '',
        headless: false,
        absoluteDate: null,
        value: null,
        timezone: null,
        outcomeId: null,
    };

    const [waitToEdit, setWaitToEdit] = useState<WaitToEditState>({
        wait: null,
        index: null,
    });
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [isMapElement, setIsMapElement] = useState<boolean>(false);
    const wait = isMapElement ? mapElement : waitToEdit;
    const [hasChangedValue, setHasChangedValue] = useState<boolean>(false);

    const isNameValid = () =>
        !isNullOrEmpty(isMapElement ? mapElement.developerName : waitToEdit?.wait?.developerName);

    const isMinimumValid = () => {
        let isValid: boolean | number | undefined | null;

        if (isMapElement) {
            isValid =
                wait.wait &&
                ((wait.wait.seconds &&
                    +wait.wait.seconds >= WAIT_BOUNDARIES.min &&
                    !wait.wait.headless) ||
                    (wait.wait.minutes && +wait.wait.minutes > 0) ||
                    (wait.wait.hours && +wait.wait.hours > 0) ||
                    (wait.wait.days && +wait.wait.days > 0));
        } else {
            isValid =
                wait.wait &&
                ((wait.wait.seconds && +wait.wait.seconds === 60) ||
                    (wait.wait.minutes && +wait.wait.minutes > 0) ||
                    (wait.wait.hours && +wait.wait.hours > 0) ||
                    (wait.wait.days && +wait.wait.days > 0));
        }

        if (isValid === undefined || isValid === null) {
            return false;
        }

        return isValid as boolean;
    };

    const isValidHeadlessWait = () => {
        if (!wait.wait?.headless) {
            return true;
        }

        const isValid =
            wait.wait &&
            ((wait.wait.minutes && +wait.wait.minutes > 0) ||
                (wait.wait.hours && +wait.wait.hours > 0) ||
                (wait.wait.days && +wait.wait.days > 0));

        if (isValid === undefined || isValid === null) {
            return false;
        }

        return isValid as boolean;
    };

    const isMaximumValid = () => {
        if (!wait.wait) {
            return false;
        }

        if (wait.wait.absoluteDate) {
            return (
                (differenceInDays(new Date(wait.wait.absoluteDate), new Date()) ?? 0) <=
                WAIT_BOUNDARIES.max
            );
        }

        let afterWait = new Date();
        afterWait = addDays(afterWait, +wait.wait.days!);
        afterWait = addHours(afterWait, +wait.wait.hours!);
        afterWait = addMinutes(afterWait, +wait.wait.minutes!);
        afterWait = addSeconds(afterWait, +wait.wait.seconds!);
        return differenceInDays(afterWait, new Date() ?? 0) <= WAIT_BOUNDARIES.max;
    };
    const allPopulated = () =>
        (wait.wait &&
            !isNullOrEmpty(wait.wait.seconds) &&
            !isNullOrEmpty(wait.wait.minutes) &&
            !isNullOrEmpty(wait.wait.hours) &&
            !isNullOrEmpty(wait.wait.days)) ??
        false;
    const noNegative = () =>
        (wait.wait &&
            +wait.wait.seconds! >= 0 &&
            +wait.wait.minutes! >= 0 &&
            +wait.wait.hours! >= 0 &&
            +wait.wait.days! >= 0) ??
        false;
    const isValidStaticTime = () =>
        isMaximumValid() && isMinimumValid() && noNegative() && allPopulated();

    const isValidRelativeTime = () => {
        if (!wait.wait || isNullOrEmpty(wait.wait.relative)) {
            return false;
        }
        const fromNowRegex =
            /^([0-9])+( )(seconds|minutes|hours|days|weeks|months|second|minute|hour|day|week|month)( from now)$/;
        const ofTheRegex = /^(start|end)( of the )(day|week|month)$/;
        const inRegex =
            /^(in )([0-9]+)( )(seconds|minutes|hours|days|weeks|months|second|minute|hour|day|week|month)$/;
        const nextRegex = /^(next )(monday|tuesday|wednesday|thursday|friday|saturday|sunday)$/;

        const input = wait.wait.relative.toLowerCase();

        if (
            input.match(fromNowRegex) ||
            input.match(ofTheRegex) ||
            input.match(inRegex) ||
            input.match(nextRegex)
        ) {
            return true;
        }

        return false;
    };
    const isAbsolutePopulated = () => wait.wait && !isNullOrEmpty(wait.wait.absoluteDate);
    const isValidAbsoluteTime = () => (isAbsolutePopulated() ?? false) && isMaximumValid();
    const isValidValueContentType = () =>
        wait?.wait?.value &&
        (!(hasChangedValue || wait.testProps?.hasChanged) || //if the value hasn't been changed we don't need to check - we also don't have access to the content type
            (wait.wait.value.contentType !== null &&
                ['ContentString', 'ContentDateTime', 'ContentDate', 'ContentNumber'].includes(
                    wait.wait.value.contentType,
                )));
    const isValidValue = () => {
        if (!wait.wait) {
            return false;
        }
        const validContentType =
            isValidValueContentType() !== undefined && (isValidValueContentType() ?? false);
        return !isNullOrEmpty(wait.wait.value) && validContentType;
    };
    const isValidPageWait = () => !isNullOrEmpty(wait.wait?.outcomeId);
    const isValidWait = () => {
        const isValid =
            isNameValid() &&
            (isValidStaticTime() ||
                isValidRelativeTime() ||
                isValidAbsoluteTime() ||
                isValidValue()) &&
            (isMapElement || (!isMapElement && isValidPageWait()));
        if (isValid === undefined || isValid === null) {
            return false;
        }

        return isValid;
    };

    const onCreateWait = () => {
        const totalWaits = mapElement.waits ? mapElement.waits.length : 0;
        setWaitToEdit({
            wait: { ...initialWait },
            index: totalWaits + 1,
        });
        onSwitchScreen(screens.wait);
    };

    const onEditWait = (wait: Wait, index: number) => {
        setWaitToEdit({ wait, index });
        onSwitchScreen(screens.wait);
    };

    const onDeleteWait = (index: number) => {
        setMapElement({
            ...mapElement,
            waits: mapElement?.waits?.filter((_, i) => i !== index) ?? [],
        });
    };

    const onSelectDeveloperName = (developerName: string) =>
        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                developerName,
            },
        });

    const onSelectWaitType = (waitType: string) =>
        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                waitType,
            },
        });

    const onApplyWait = (index: number) => {
        setHasSubmitted(false);
        const waitExists = mapElement.waits ? mapElement.waits.find((_, i) => i === index) : null;
        const waitToEditLocal = waitToEdit.wait;

        if (waitToEditLocal) {
            const waits: Wait[] | null = waitExists
                ? (mapElement?.waits?.map((existingWait, i) =>
                      i === index ? waitToEditLocal : existingWait,
                  ) ?? [])
                : [...(mapElement.waits ?? []), waitToEditLocal ?? []];

            setMapElement({ ...mapElement, waits });
        }

        onSwitchScreen(props.defaultScreen);
    };

    const onReturnToDefaultScreen = () => {
        setHasSubmitted(false);
        onSwitchScreen(props.defaultScreen);
    };

    const setDefaultWait = () => {
        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...initialWait,
                developerName: waitToEdit?.wait?.developerName,
                outcomeId: waitToEdit?.wait?.outcomeId,
                headless: waitToEdit?.wait?.headless,
            },
        });
    };

    const tryParseFloat = (string: string) => {
        const number: number = Number.parseFloat(string);
        return Number.isNaN(number) ? null : number;
    };

    const onTimezoneChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
        if (isMapElement) {
            setMapElement({
                ...mapElement,
                wait: {
                    ...mapElement.wait,
                    timezone: value,
                },
            });
            return;
        }
        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                timezone: value,
            },
        });
    };
    const onDaysChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (isMapElement) {
            setMapElement({
                ...mapElement,
                wait: {
                    ...mapElement.wait,
                    days: tryParseFloat(value),
                },
            });
            return;
        }
        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                days: tryParseFloat(value),
            },
        });
    };
    const onHoursChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (isMapElement) {
            setMapElement({
                ...mapElement,
                wait: {
                    ...mapElement.wait,
                    hours: tryParseFloat(value),
                },
            });
            return;
        }
        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                hours: tryParseFloat(value),
            },
        });
    };
    const onMinutesChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (isMapElement) {
            setMapElement({
                ...mapElement,
                wait: {
                    ...mapElement.wait,
                    minutes: tryParseFloat(value),
                },
            });
            return;
        }
        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                minutes: tryParseFloat(value),
            },
        });
    };
    const onSecondsChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (isMapElement) {
            setMapElement({
                ...mapElement,
                wait: {
                    ...mapElement.wait,
                    seconds: tryParseFloat(value),
                },
            });
            return;
        }
        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                seconds: tryParseFloat(value),
            },
        });
    };
    const onRelativeChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        if (isMapElement) {
            setMapElement({
                ...mapElement,
                wait: {
                    ...mapElement.wait,
                    relative: value,
                },
            });
            return;
        }

        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                relative: value,
            },
        });
    };

    const onAbsoluteChange = (date: string) => {
        if (isMapElement) {
            setMapElement({
                ...mapElement,
                wait: {
                    ...mapElement.wait,
                    absoluteDate: date,
                },
            });
            return;
        }

        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                absoluteDate: date,
            },
        });
    };

    const onValueChanged = (value: ValueElementIdReferenceAPI | null) => {
        setHasChangedValue(true);
        if (isMapElement) {
            setMapElement({
                ...mapElement,
                wait: {
                    ...mapElement.wait,
                    value: value,
                },
            });
            return;
        }
        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                value: value,
            },
        });
    };

    const onUpdateName = (developerName: string) => {
        if (isMapElement) {
            setMapElement({
                ...mapElement,
                developerName,
            });
            return;
        }
        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                developerName,
            },
        });
    };

    const onOutcomeChange = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                outcomeId: value,
            },
        });
    };

    const onWaitTypeChange = () => {
        setMapElement({
            ...mapElement,
            wait: {
                ...initialWait,
                headless: mapElement?.wait?.headless,
            },
        });
    };

    const onHeadlessChange = (args: { event: ChangeEvent<HTMLInputElement>; isOn: boolean }) => {
        if (isMapElement) {
            setMapElement({
                ...mapElement,
                wait: {
                    ...mapElement.wait,
                    headless: args.isOn,
                },
            });
            return;
        }
        setWaitToEdit({
            ...waitToEdit,
            wait: {
                ...waitToEdit.wait,
                headless: args.isOn,
            },
        });
    };

    const contextValue: WaitContext = {
        waitToEdit,
        onCreateWait,
        onEditWait,
        onDeleteWait,
        onSelectDeveloperName,
        onSelectWaitType,
        onApplyWait,
        onReturnToDefaultScreen,
        hasSubmitted,
        setHasSubmitted,
        setDefaultWait,
        isValidWait,
        onTimezoneChange,
        onDaysChange,
        onHoursChange,
        onMinutesChange,
        onSecondsChange,
        onRelativeChange,
        onAbsoluteChange,
        onValueChanged,
        onUpdateName,
        onOutcomeChange,
        setIsMapElement,
        onWaitTypeChange,
        onHeadlessChange,
        initialWait,
        isNameValid,
        isMaximumValid,
        isMinimumValid,
        isValidHeadlessWait,
        noNegative,
        allPopulated,
        isValidRelativeTime,
        isValidAbsoluteTime,
        isAbsolutePopulated,
        isValidValue,
        isValidValueContentType,
        isValidPageWait,
    };

    return <Context.Provider value={contextValue}>{props.children}</Context.Provider>;
};

const useWait = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useWait must be used within a WaitProvider');
    }
    return context;
};

export { WaitProvider, useWait };
