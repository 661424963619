import DataActionConfiguration from '../common/dataActions/DataActionConfiguration';
import OutcomeDetails from '../../../../../ts/components/flow/elementConfigurations/common/OutcomeDetails';
import screens from '../../../../../ts/components/flow/elementConfigurations/common/screens';
import { useMapElement } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import DeleteConfiguration from './DeleteConfiguration';
import ScreenRouter from '../../../../../ts/components/generic/ScreenRouter';

const Delete = () => {
    const {
        currentScreen,
        mapElement,
        selectedOutcomeIndex,
        onReturnToDefaultScreen,
        setSelectedOutcomeIndex,
    } = useMapElement();

    return (
        <ScreenRouter currentScreen={currentScreen}>
            <DeleteConfiguration screen={screens.delete} />
            <DataActionConfiguration screen={screens.dataAction} />
            <OutcomeDetails
                screen={screens.outcome}
                outcome={mapElement?.outcomes?.[selectedOutcomeIndex]}
                onSave={onReturnToDefaultScreen}
                onCancel={() => {
                    onReturnToDefaultScreen();
                    setSelectedOutcomeIndex(null);
                }}
            />
        </ScreenRouter>
    );
};

export default Delete;
