import { useEffect } from 'react';
import Loader from '../../../../../ts/components/loader/Loader';
import { addNotification as addNotificationAction } from '../../../../actions/reduxActions/notification';
import { generateSubFlowArgumentSelectors } from './subflowUtils';
import { connect } from 'react-redux';
import { ACCESS_LEVELS } from '../../../../../ts/constants';
import translations from '../../../../../ts/translations';
import Table from '../../../../../ts/components/generic/Table';
import { useMapElement } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import ValueSelectorModal from '../../../../../ts/components/values/selector/ValueSelectorModal';

/**
 * @param {Function} setSubFlowArguments Callback for setting arguments in the root components state
 * @param {String} subFlowId
 * @param {Array} valueReferences Value element references returned from api
 * @param {String} access Value access type (INPUT, OUTPUT or INPUT_OUTPUT)
 * @param {Array} subFlowArguments The raw arguments metadata for the subflow map element
 * @param {Function} subFlowArgumentsToBeRendered The subflow arguments formatted as such to tell
 * the UI how to render the argument e.g how to display the value selector etc
 *
 * @param {Function} togglePicker Callback to trigger opening/closing the value picker modal
 * @param {Function} onSelect Callback for updating a single argument
 * @param {Boolean} isLoading Determines whether to show a loading indicator
 *
 * @description Renders subflow arguments inside of a table. The left column displays the
 * formal argument defined in the subflow itself (i.e. a value that is imported into the Flow),
 * and the right column displays the actual value that you will be passing down to the subflow from
 * the parent flow. Users can use the value picker to set this argument.
 */
const InputOutputValues = ({
    tenantId,
    setSubFlowArguments,
    subFlowId,
    valueReferences,
    access,
    subFlowArguments,
    subFlowArgumentsToBeRendered,
    onSelect,
    isLoading,
    addNotification,
}) => {
    const { container } = useMapElement();

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        getTableContents();
    }, [subFlowId]);

    const getTableContents = () => {
        generateSubFlowArgumentSelectors(
            access,
            tenantId,
            subFlowId,
            valueReferences,
            subFlowArguments,
        )
            .then((subFlowArgumentsSelectors) =>
                setSubFlowArguments(subFlowArgumentsSelectors, access),
            )
            .catch(({ message }) => {
                addNotification({
                    type: 'error',
                    message,
                    isPersistent: true,
                });
            });
    };

    // We are only interested in displaying the values of the specified access type
    const rows = subFlowArgumentsToBeRendered.filter(
        (subFlowArgument) => subFlowArgument.access === access,
    );

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_accepted_value,
            renderCell: ({ item }) => item.formalValueName,
        },
        {
            renderHeader: () => ACCESS_LEVELS[access].description,
            renderCell: ({ item }) =>
                ACCESS_LEVELS[access] === ACCESS_LEVELS.PUBLIC ? (
                    ACCESS_LEVELS[access].description
                ) : (
                    <ValueSelectorModal
                        value={item.selectedValueMeta}
                        onChange={(value) => {
                            onSelect(value, item.id);
                        }}
                        container={container}
                    />
                ),
        },
    ];

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <h2>{ACCESS_LEVELS[access].labelShort}</h2>
                    <Table
                        key={ACCESS_LEVELS[access].label}
                        columns={columns}
                        items={rows}
                        isLoading={isLoading}
                        pagination={true}
                    />
                </>
            )}
        </div>
    );
};

const mapDispatchToProps = {
    addNotification: addNotificationAction,
};

export default connect(null, mapDispatchToProps)(InputOutputValues);
