import { useState, useEffect } from 'react';
import ButtonPrimary from '../../../../../../ts/components/buttons/ButtonPrimary';
import Glyphicon from '../../../../../../ts/components/generic/Glyphicon';
import { getValueList } from '../../../../../../ts/sources/value';
import { useMapElement } from '../../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import { isNullOrEmpty } from '../../../../../../ts/utils/guard';
import { useListener } from '../../../../../../ts/components/flow/elementConfigurations/contextProviders/ListenerProvider';
import translations from '../../../../../../ts/translations';
import { Trash } from '@phosphor-icons/react';
import DraggableTable from '../../../../../../ts/components/generic/DraggableTable';
import { getValueName } from '../../../../../../ts/components/values/selector/value-selector-utils';

const ListenerList = () => {
    const [valueReferences, setValueReferences] = useState([]);

    const { mapElement, notifyError, setMapElement } = useMapElement();
    const { onCreateListener, onEditListener, onDeleteListener } = useListener();

    const onReorderListeners = (listeners) => {
        setMapElement({
            ...mapElement,
            listeners: listeners,
        });
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!Array.isArray(mapElement?.listeners)) {
            return;
        }

        // Get the list of value reference ids from the items so we can request just those value references
        const valueReferenceIds = mapElement.listeners.reduce((idList, listener) => {
            if (listener.valueElementToReferenceForListeningId !== null) {
                idList.push(listener.valueElementToReferenceForListeningId.id);
            }
            return idList;
        }, []);

        if (valueReferenceIds.length === 0) {
            return;
        }

        const filter = { id: valueReferenceIds };

        (async () => {
            try {
                const response = await getValueList(filter);

                setValueReferences(response);
            } catch (error) {
                notifyError(error);
            }
        })();
    }, []);

    const getValueCellText = (itemReferenceId) => {
        if (isNullOrEmpty(itemReferenceId)) {
            return null;
        }

        const valueReference = valueReferences.find(
            (valueReference) =>
                valueReference.id === itemReferenceId.id &&
                // typeElementPropertyId can be either null or undefined
                (valueReference?.typeElementPropertyId ?? null) ===
                    (itemReferenceId?.typeElementPropertyId ?? null),
        );

        if (!valueReference) {
            return null;
        }

        return getValueName(valueReference);
    };

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item, rowIndex }) => (
                <button
                    className="link-emulate"
                    title={`Edit ${item.developerName}`}
                    onClick={() => onEditListener(item, rowIndex)}
                    type="button"
                >
                    {item.developerName ?? ''}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_listener_type,
            renderCell: ({ item: { listenerType } }) => listenerType ?? '',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_value,
            renderCell: ({ item: { valueElementToReferenceForListeningId } }) =>
                getValueCellText(valueElementToReferenceForListeningId),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item, rowIndex }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`Delete ${item.developerName}`}
                        className="table-icon table-icon-delete margin-left"
                        aria-label={`Delete ${item.developerName}`}
                        onClick={() => onDeleteListener(rowIndex)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '7.5rem',
        },
    ];

    return (
        <>
            <h4>Listeners</h4>
            <div className="margin-bottom flex">
                <ButtonPrimary onClick={onCreateListener}>
                    <Glyphicon glyph="plus" />
                    Add Listener
                </ButtonPrimary>
            </div>
            <DraggableTable
                columns={columns}
                wrapperClassName="margin-bottom-large"
                items={mapElement.listeners ?? []}
                testId="listener-list"
                tableId="listener-list"
                onReorder={onReorderListeners}
            />
        </>
    );
};

export default ListenerList;
