import { useState, useEffect } from 'react';
import FormGroup from '../../../../../../../ts/components/generic/FormGroup';
import translations from '../../../../../../../ts/translations';
import FilterCriterias from './FilterCriterias';
import { getType } from '../../../../../../../ts/sources/type';
import Loader from '../../../../../../../ts/components/loader/Loader';

import { useDataAction } from '../../../contextProviders/DataActionProvider';
import { useMapElement } from '../../../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import { isNullOrEmpty } from '../../../../../../../ts/utils/guard';

const COMPARATORS = [
    { label: 'And', value: 'AND' },
    { label: 'Or', value: 'OR' },
];

const ORDER_DIRECTIONS = [
    { label: 'Ascending', value: 'ASC' },
    { label: 'Descending', value: 'DESC' },
];

const FilterByWhereClause = ({ comparisonType, hasSubmitted }) => {
    const [type, setType] = useState(null);

    const {
        onSetFilterComparisonType,
        onSetFilterOrderDirectionType,
        onSetFilterOrderByTypePropertyId,
        onSetFilterRecordLimit,
        dataActionToEdit,
        filterToEdit,
    } = useDataAction();

    const { notifyError } = useMapElement();
    const { dataAction } = dataActionToEdit;

    const orderByDirectionType = filterToEdit?.orderByDirectionType;
    const orderByTypeElementPropertyId = filterToEdit?.orderByTypeElementPropertyId;
    const recordLimit = filterToEdit?.limit;
    const typeElementId = dataAction.objectDataRequest?.typeElementId;

    const isFilteredByWhereClause = filterToEdit?.where && !filterToEdit?.filterId;

    const fetchType = async () => {
        try {
            const typeResult = await getType(typeElementId);
            setType(typeResult);
        } catch (error) {
            notifyError(error);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchType();
    }, []);

    if (!isFilteredByWhereClause) {
        return null;
    }

    if (!type) {
        return <Loader />;
    }

    return (
        <>
            <FilterCriterias />
            <FormGroup
                label={translations.DATA_ACTION_comparator_label}
                htmlFor="comparator-type"
                isRequired
                validationMessage={translations.MAP_ELEMENT_value_field_validation_message}
                isValid={!isNullOrEmpty(comparisonType)}
                showValidation={hasSubmitted}
            >
                <select
                    id="comparator-type"
                    defaultValue={comparisonType}
                    onChange={(e) => onSetFilterComparisonType(e.target.value)}
                    placeholder={translations.DATA_ACTION_comparator_label}
                    className="form-control form-control-width"
                >
                    <option value="">Select a comparator</option>
                    {COMPARATORS.map((comparator) => (
                        <option key={comparator.value} value={comparator.value}>
                            {comparator.label}
                        </option>
                    ))}
                </select>
            </FormGroup>

            <FormGroup
                label={translations.DATA_ACTION_order_by_type_property}
                htmlFor="type-properties"
                isRequired={false}
            >
                <select
                    id="type-properties"
                    value={orderByTypeElementPropertyId}
                    onChange={(e) => onSetFilterOrderByTypePropertyId(e.target.value)}
                    className="form-control form-control-width"
                >
                    <option value="">Select a property</option>
                    {type.properties.map((property) => (
                        <option key={property.id} value={property.id}>
                            {property.developerName}
                        </option>
                    ))}
                </select>
            </FormGroup>

            <FormGroup
                label={translations.DATA_ACTION_order_direction_label}
                htmlFor="order-direction"
                isRequired={false}
            >
                <select
                    id="order-direction"
                    defaultValue={orderByDirectionType || ''}
                    onChange={(e) => onSetFilterOrderDirectionType(e.target.value)}
                    placeholder={translations.DATA_ACTION_order_direction_label}
                    className="form-control form-control-width"
                >
                    <option value="">Select an order direction</option>
                    {ORDER_DIRECTIONS.map((direction) => (
                        <option key={direction.value} value={direction.value}>
                            {direction.label}
                        </option>
                    ))}
                </select>
            </FormGroup>

            <FormGroup
                label={translations.DATA_ACTION_number_of_records_label}
                htmlFor="number-records"
                isRequired={false}
            >
                <input
                    id="number-records"
                    defaultValue={recordLimit || 0}
                    onChange={(e) => onSetFilterRecordLimit(e.target.value)}
                    maxLength="255"
                    required
                    className="form-control form-control-width"
                    type="number"
                    min="0"
                />
            </FormGroup>
        </>
    );
};

export default FilterByWhereClause;
