import { useState, createContext, useContext } from 'react';
import screens from '../../../../../ts/components/flow/elementConfigurations/common/screens';

import { useMapElement } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import { isNullOrEmpty } from '../../../../../ts/utils/guard';

const Context = createContext(undefined);

const MessageActionProvider = ({ defaultScreen, children }) => {
    const { mapElement, setMapElement, onSwitchScreen } = useMapElement();

    const initialMessageAction = {
        attributes: null,
        developerName: null,
        disabled: false,
        inputs: null,
        order: 0,
        outputs: null,
        serviceActionName: null,
        serviceElementDeveloperName: null,
        serviceElementId: null,
        uriPart: null,
    };

    const [messageActionToEdit, setMessageActionToEdit] = useState({
        messageAction: null,
        index: null,
    });

    const onCreateMessageAction = () => {
        const totalMessageActions = mapElement.messageActions.length;
        setMessageActionToEdit({
            messageAction: { ...initialMessageAction, order: totalMessageActions },
            index: totalMessageActions + 1,
        });
        onSwitchScreen(screens.messageActions);
    };

    const onEditMessageAction = (messageAction, index) => {
        setMessageActionToEdit({ messageAction, index, isEditing: true });
        onSwitchScreen(screens.messageActions);
    };

    const onDeleteMessageAction = (index) => {
        setMapElement({
            ...mapElement,
            messageActions: mapElement.messageActions.filter((_, i) => i !== index),
        });
    };

    const onChangeName = (developerName) => {
        setMessageActionToEdit({
            ...messageActionToEdit,
            messageAction: { ...messageActionToEdit.messageAction, developerName },
        });
    };

    const onChangeOrder = (order) => {
        setMessageActionToEdit({
            ...messageActionToEdit,
            messageAction: { ...messageActionToEdit.messageAction, order },
        });
    };

    const onChangeDisabledFlag = (disabled) => {
        setMessageActionToEdit({
            ...messageActionToEdit,
            messageAction: { ...messageActionToEdit.messageAction, disabled },
        });
    };

    const onChangeService = (serviceElementId, serviceElementDeveloperName) => {
        setMessageActionToEdit({
            ...messageActionToEdit,
            messageAction: {
                ...messageActionToEdit.messageAction,
                serviceElementId,
                serviceElementDeveloperName,
            },
        });
    };

    const selectMessageAction = (messageAction) => {
        const { developerName, uriPart, serviceInputs, serviceOutputs } = messageAction;
        setMessageActionToEdit({
            ...messageActionToEdit,
            messageAction: {
                ...messageActionToEdit.messageAction,
                inputs: serviceInputs,
                outputs: serviceOutputs,
                developerName,
                uriPart,
            },
        });
    };

    const updateValueElementReferences = (listName, inputOutputList, value, item) => {
        if (listName !== 'inputs' && listName !== 'outputs') {
            return inputOutputList;
        }

        const propertyName =
            listName === 'outputs' ? 'valueElementToApplyId' : 'valueElementToReferenceId';

        return inputOutputList.map((ioConfig) => {
            if (ioConfig.developerName !== item.developerName) {
                return ioConfig;
            }

            if (isNullOrEmpty(value)) {
                return {
                    ...ioConfig,
                    [propertyName]: null,
                    valueElementToReferenceDeveloperName: null,
                };
            }

            return {
                ...ioConfig,
                [propertyName]: {
                    id: value.id,
                    typeElementPropertyId: value.typeElementPropertyId,
                },
            };
        });
    };

    const setInputValue = (value, item) => {
        const updatedReferences = updateValueElementReferences(
            'inputs',
            messageActionToEdit.messageAction.inputs,
            value,
            item,
        );

        setMessageActionToEdit({
            ...messageActionToEdit,
            messageAction: {
                ...messageActionToEdit.messageAction,
                inputs: updatedReferences,
            },
        });
    };

    const setOutputValue = (value, item) => {
        const updatedReferences = updateValueElementReferences(
            'outputs',
            messageActionToEdit.messageAction.outputs,
            value,
            item,
        );

        setMessageActionToEdit({
            ...messageActionToEdit,
            messageAction: {
                ...messageActionToEdit.messageAction,
                outputs: updatedReferences,
            },
        });
    };

    const setInputsAndOutputs = ({ inputs, outputs }) =>
        setMessageActionToEdit({
            ...messageActionToEdit,
            messageAction: {
                ...messageActionToEdit.messageAction,
                inputs,
                outputs,
            },
        });

    const onApplyMessageAction = (index) => {
        const messageActionExists = mapElement.messageActions
            ? mapElement.messageActions.find((_, i) => i === index)
            : null;
        const messageActions = messageActionExists
            ? mapElement.messageActions.map((existingMessageAction, i) =>
                  i === index ? messageActionToEdit.messageAction : existingMessageAction,
              )
            : [...mapElement.messageActions, messageActionToEdit.messageAction];

        setMapElement({ ...mapElement, messageActions });
        onSwitchScreen(defaultScreen);
    };

    const onReturnToDefaultScreen = () => {
        onSwitchScreen(defaultScreen);
    };

    const contextValue = {
        messageActionToEdit,
        onCreateMessageAction,
        onEditMessageAction,
        onDeleteMessageAction,
        onChangeName,
        onChangeOrder,
        onChangeDisabledFlag,
        onChangeService,
        selectMessageAction,
        setInputValue,
        setOutputValue,
        onApplyMessageAction,
        onReturnToDefaultScreen,
        setInputsAndOutputs,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useMessageAction = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useMessageAction must be used within a MessageActionProvider');
    }
    return context;
};

export { MessageActionProvider, useMessageAction };
