import { useMapElement } from '../contextProviders/MapElementProvider';
import ProcessConfiguration from './ProcessConfiguration';
import ProcessDetails from './ProcessDetails';
import OutcomeDetails from '../common/OutcomeDetails';
import ProcessPropertiesDetails from './ProcessPropertiesDetails';

const ProcessRouter = () => {
    const {
        currentScreen,
        mapElement,
        onSwitchScreen,
        selectedOutcomeIndex,
        setSelectedOutcomeIndex,
    } = useMapElement();

    switch (currentScreen) {
        case 'process':
            return <ProcessConfiguration />;

        case 'processDetails':
            return <ProcessDetails />;

        case 'processProperties':
            return <ProcessPropertiesDetails />;

        case 'outcome': {
            const selectedOutcome = mapElement.outcomes?.[selectedOutcomeIndex ?? 0];

            return selectedOutcome ? (
                <OutcomeDetails
                    outcome={selectedOutcome}
                    onSave={() => onSwitchScreen('process')}
                    onCancel={() => {
                        onSwitchScreen('process');
                        setSelectedOutcomeIndex(null);
                    }}
                />
            ) : null;
        }

        default:
            return null;
    }
};

export default ProcessRouter;
