import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TAB_TYPES, MAP_ELEMENT_TYPES, DIRECTION } from '../../../../../ts/constants';
import { getFlowSharedElements } from '../../../../../ts/sources/flow';
import translations from '../../../../../ts/translations';
import { generateRouteUrl } from '../../../../../ts/utils/routing';
import { getByID } from '../../../../../ts/utils/collection';
import { isNullOrEmpty } from '../../../../../ts/utils/guard';
import { stringContains } from '../../../../../ts/utils/string';
import ButtonDefault from '../../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../../ts/components/buttons/ButtonPrimary';
import FormGroup from '../../../../../ts/components/generic/FormGroup';
import Glyphicon from '../../../../../ts/components/generic/Glyphicon';
import HTMLInput from '../../../../../ts/components/generic/HTMLEditor/HTMLInput';
import Footer from '../common/Footer';
import ListenerList from '../common/listeners/ListenerList';
import MessageActionsList from '../common/messageActions/MessageActionsList';
import NameInput from '../../../../../ts/components/flow/elementConfigurations/common/NameInput';
import NavigationOverrideList from '../common/navigationOverrides/NavigationOverrideList';
import OutcomeList from '../../../../../ts/components/flow/elementConfigurations/common/outcomes/OutcomeList';
import { useMapElement } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import WaitList from '../../../../../ts/components/flow/elementConfigurations/common/wait/WaitList';
import { getElements } from '../../../../../ts/config/graphElements';
import ModalBody from '../../../../../ts/components/generic/modal/ModalBody';
import ModalFooter from '../../../../../ts/components/generic/modal/ModalFooter';
import { getPageList, savePage } from '../../../../../ts/sources/page';
import AsyncSelect from 'react-select/async';
import { getSharedStyles } from '../../../../../ts/utils/select';

const PageConfiguration = () => {
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [onlyShowPagesInFlow, setOnlyShowPagesInFlow] = useState(true);
    const [selectedPage, setSelectedPage] = useState(null);

    const navigate = useNavigate();

    const {
        tenantId,
        flowId,
        mapElement,
        setMapElement,
        onUpdateName,
        onUpdateTitle,
        onSaveMapElement,
        onClose,
        onUpdateNotAuthorizedMessage,
        onUpdateComments,
        notifyError,
    } = useMapElement();

    const { pageElementId, pageElementDeveloperName } = mapElement;

    useEffect(() => {
        if (pageElementId && pageElementDeveloperName) {
            setSelectedPage({
                id: pageElementId,
                developerName: pageElementDeveloperName,
            });
        } else {
            setSelectedPage(null);
        }
    }, [pageElementId, pageElementDeveloperName]);

    const { developerName, developerSummary, title } = mapElement;

    const isPageElementNameValid = !isNullOrEmpty(developerName);
    const isSelectedPageValid = !isNullOrEmpty(selectedPage);
    const graphElements = getElements();

    const createNewPage = () => {
        const newPagePayload = {
            developerName: isNullOrEmpty(developerName) ? 'New Page' : developerName,
            elementType: 'PAGE_LAYOUT',
            pageContainers: [
                {
                    developerName: 'main',
                    containerType: 'VERTICAL_FLOW',
                },
            ],
        };

        return savePage(newPagePayload);
    };

    const onNewPageClick = async () => {
        try {
            const page = await createNewPage();

            onSelectPage(page);

            const routeOptions = {
                tabType: TAB_TYPES.page,
                tenantId,
                options: {
                    pageName: page.developerName,
                    elementId: page.id,
                },
            };

            const route = generateRouteUrl(routeOptions);

            navigate(route);
        } catch (error) {
            notifyError(error);
        }
    };

    const onEditPageClick = () => {
        const route = generateRouteUrl({
            tabType: TAB_TYPES.page,
            tenantId,
            options: {
                elementId: selectedPage.id,
            },
        });

        navigate(route);
    };

    const onSelectPage = (page) => {
        setMapElement({
            ...mapElement,
            pageElementId: page?.id ?? null,
            pageElementDeveloperName: page?.developerName ?? null,
        });
    };

    const onSave = () => {
        setHasSubmitted(true);
        if (isPageElementNameValid && isSelectedPageValid) {
            onSaveMapElement(mapElement);
        }
    };

    const queryAllPages = (searchQuery) =>
        getPageList({
            search: searchQuery,
            page: 1,
            pageSize: 100,
            orderBy: 'developerName',
            orderDirection: DIRECTION.asc,
        })
            .then((response) => response.items)
            .catch(notifyError);

    const queryPagesWithinFlow = (searchQuery) =>
        getFlowSharedElements(flowId, 'page', tenantId)
            .then((response) =>
                response
                    .filter((page) => stringContains(page.developerName, searchQuery))
                    .sort((a, b) => a.developerName.localeCompare(b.developerName)),
            )
            .catch(notifyError);

    const commonSelectProps = {
        defaultOptions: true,
        inputId: 'select-page',
        className: 'flex-grow form-control-width',
        value: selectedPage,
        getOptionLabel: (page) => page.developerName,
        getOptionValue: (page) => page.id,
        onChange: onSelectPage,
        placeholder: 'Search',
        isClearable: true,
        styles: getSharedStyles(),
    };

    const renderBody = () => (
        <>
            <NameInput
                isValid={isPageElementNameValid}
                showValidation={hasSubmitted}
                id="page-element-name"
                name={mapElement.developerName}
                onUpdateName={onUpdateName}
            />
            {mapElement.elementType.toLowerCase() === MAP_ELEMENT_TYPES.modal && (
                <FormGroup label="Title" htmlFor={'title'}>
                    <input
                        id={'title'}
                        value={title ?? ''}
                        onChange={(e) => onUpdateTitle(e.target.value)}
                        maxLength="255"
                        className="form-control form-control-width"
                        type="text"
                    />
                </FormGroup>
            )}
            <FormGroup
                isRequired
                label="Page Layout"
                htmlFor="select-page"
                className={'margin-top'}
                isValid={isSelectedPageValid}
                validationMessage={'Please select a Page Layout.'}
                showValidation={hasSubmitted}
            >
                <div className="flex">
                    {onlyShowPagesInFlow ? (
                        <AsyncSelect
                            {...commonSelectProps}
                            key="queryFlow"
                            loadOptions={queryPagesWithinFlow}
                        />
                    ) : (
                        <AsyncSelect
                            {...commonSelectProps}
                            key="queryAll"
                            loadOptions={queryAllPages}
                        />
                    )}

                    {isSelectedPageValid && (
                        <ButtonDefault onClick={onEditPageClick} className={'margin-left-sml'}>
                            <Glyphicon glyph={'pencil'} /> Edit Layout
                        </ButtonDefault>
                    )}
                    <ButtonPrimary onClick={onNewPageClick} className={'margin-left'}>
                        <Glyphicon glyph={'plus'} />
                        New Layout
                    </ButtonPrimary>
                </div>
            </FormGroup>
            <div className={'from-group'}>
                <label>
                    <input
                        type={'checkbox'}
                        checked={onlyShowPagesInFlow}
                        onChange={({ target: { checked } }) => {
                            setOnlyShowPagesInFlow(checked);
                        }}
                    />
                    Only show Page Layouts already being used in this Flow
                </label>
            </div>
            <OutcomeList />
            <MessageActionsList />
            <ListenerList />
            <NavigationOverrideList />
            <WaitList />
            <h4>Feedback</h4>
            <FormGroup label="Message to show users that are not authorized to take action">
                <HTMLInput
                    contentValue={mapElement.notAuthorizedMessage}
                    onChange={(userContent) => onUpdateNotAuthorizedMessage(userContent)}
                    hasLinkPlugin={true}
                    hasImagePlugin={true}
                    hasValuePickerPlugin={true}
                    height={250}
                />
            </FormGroup>
            <FormGroup
                htmlFor="message-comments"
                label={`Comments about this ${
                    getByID(mapElement.elementType.toLowerCase(), graphElements).name
                }`}
            >
                <textarea
                    id="message-comments"
                    value={developerSummary}
                    className="form-control form-textarea"
                    onChange={({ target: { value } }) => onUpdateComments(value)}
                />
            </FormGroup>
        </>
    );

    const renderFooter = () => {
        return (
            <Footer
                save={onSave}
                saveButtonText={translations.GRAPH_config_panel_save}
                cancel={onClose}
            />
        );
    };

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default PageConfiguration;
