import { pathOr } from 'ramda';
import { useEffect, useState } from 'react';
import { getType } from '../../../../../../../ts/sources/type';
import translations from '../../../../../../../ts/translations';
import { isNullOrEmpty } from '../../../../../../../ts/utils/guard';
import Breadcrumb from '../../../../../../../ts/components/Breadcrumb';
import FormGroup from '../../../../../../../ts/components/generic/FormGroup';
import Loader from '../../../../../../../ts/components/loader/Loader';
import { useDataAction } from '../../../contextProviders/DataActionProvider';
import { useMapElement } from '../../../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import Footer from '../../Footer';
import ModalBody from '../../../../../../../ts/components/generic/modal/ModalBody';
import ModalFooter from '../../../../../../../ts/components/generic/modal/ModalFooter';
import ValueSelectorModal from '../../../../../../../ts/components/values/selector/ValueSelectorModal';

const CRITERIAS = [
    { label: 'Equal To', value: 'EQUAL' },
    { label: 'Not Equal To', value: 'NOT_EQUAL' },
    { label: 'Greater Than', value: 'GREATER_THAN' },
    { label: 'Greater Than Or Equal To', value: 'GREATER_THAN_OR_EQUAL' },
    { label: 'Less Than', value: 'LESS_THAN' },
    { label: 'Less Than Or Equal To', value: 'LESS_THAN_OR_EQUAL' },
    { label: 'Starts With', value: 'STARTS_WITH' },
    { label: 'Ends With', value: 'ENDS_WITH' },
    { label: 'Contains', value: 'CONTAINS' },
    { label: 'Is Empty', value: 'IS_EMPTY' },
];

const FilterCriteria = () => {
    const [hasSubmitted, updateHasSubmitted] = useState(false);
    const [type, setType] = useState(null);

    const { breadcrumbs, notifyError, container } = useMapElement();

    const {
        onReturnToDataActionFilterScreen,
        dataActionToEdit,
        criteriaToEdit,
        onSetCriteriaTypePropertyId,
        onSetCriteriaType,
        onSetCriteriaValueElementToReference,
        onApplyCriteria,
    } = useDataAction();

    const { dataAction } = dataActionToEdit;
    const { criteria, index } = criteriaToEdit;

    const typeElementId = dataAction.objectDataRequest?.typeElementId;

    const { columnTypeElementPropertyId, criteriaType } = criteria;

    // When choosing a value to be applied to the filter
    // we only want to choose from values that are of the same type
    // as the property that we are filtering by
    let contentTypeFilter =
        columnTypeElementPropertyId && type
            ? type.properties.find((property) => property.id === columnTypeElementPropertyId)
                  .contentType
            : null;

    if (criteriaType === 'IS_EMPTY') {
        contentTypeFilter = 'ContentBoolean';
    }

    const checkRequiredFields = () => ({
        columnTypeElementPropertyId: pathOr('', ['columnTypeElementPropertyId'], criteria),
        criteriaType: pathOr('', ['criteriaType'], criteria),
        valueElementToReferenceId: pathOr('', ['valueElementToReferenceId', 'id'], criteria),
    });

    const onSave = () => {
        updateHasSubmitted(true);
        const isFormValid = Object.values(checkRequiredFields()).every(
            (field) => !isNullOrEmpty(field),
        );

        if (isFormValid) {
            onApplyCriteria(index);
        }
    };

    const fetchType = async () => {
        try {
            const typeResult = await getType(typeElementId);
            setType(typeResult);
        } catch (error) {
            notifyError(error);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        fetchType();
    }, []);

    const renderBody = () => {
        const { columnTypeElementPropertyId, criteriaType, valueElementToReferenceId } =
            checkRequiredFields();
        return (
            <>
                <div className="margin-bottom">
                    <Breadcrumb trail={breadcrumbs.trail} activeItemId={breadcrumbs.activeItemId} />
                </div>

                <FormGroup
                    label={translations.DATA_ACTION_property_to_filter_by_label}
                    htmlFor="type-properties"
                    isRequired
                    validationMessage={translations.MAP_ELEMENT_property_field_validation_message}
                    isValid={!isNullOrEmpty(columnTypeElementPropertyId)}
                    showValidation={hasSubmitted}
                >
                    <select
                        id="type-properties"
                        value={columnTypeElementPropertyId}
                        onChange={(e) =>
                            onSetCriteriaTypePropertyId(
                                e.target.value,
                                e.target[e.target.selectedIndex].text,
                            )
                        }
                        className="form-control form-control-width"
                    >
                        <option value="">Select a property</option>
                        {type.properties.map((property) => (
                            <option key={property.id} value={property.id}>
                                {property.developerName}
                            </option>
                        ))}
                    </select>
                </FormGroup>

                <FormGroup
                    label={translations.DATA_ACTION_criteria_filter}
                    htmlFor="criteria-type"
                    isRequired
                    validationMessage={translations.MAP_ELEMENT_criteria_field_validation_message}
                    isValid={!isNullOrEmpty(criteriaType)}
                    showValidation={hasSubmitted}
                >
                    <select
                        id="criteria-type"
                        defaultValue={criteriaType}
                        onChange={(e) => {
                            onSetCriteriaType(
                                e.target.value,
                                e.target[e.target.selectedIndex].text,
                            );
                        }}
                        className="form-control form-control-width"
                    >
                        <option value="">Select a criteria</option>
                        {CRITERIAS.map((criteria) => (
                            <option key={criteria.value} value={criteria.value}>
                                {criteria.label}
                            </option>
                        ))}
                    </select>
                </FormGroup>
                <FormGroup
                    required
                    label={translations.DATA_ACTION_criteria_value_applied_label}
                    validationMessage={translations.MAP_ELEMENT_value_field_validation_message}
                    isValid={!isNullOrEmpty(valueElementToReferenceId)}
                    showValidation={hasSubmitted}
                >
                    <ValueSelectorModal
                        value={criteria.valueElementToReferenceId}
                        onChangeAsValueReference={onSetCriteriaValueElementToReference}
                        container={container}
                        contentType={contentTypeFilter}
                    />
                </FormGroup>
            </>
        );
    };

    const renderFooter = () => (
        <Footer
            save={onSave}
            saveButtonText={translations.DATA_ACTION_save_criteria_button_text}
            cancel={onReturnToDataActionFilterScreen}
        />
    );

    if (!type) {
        return <Loader />;
    }

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default FilterCriteria;
