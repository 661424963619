import MapElementModal from '../../../../../ts/components/graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../../ts/constants';
import screens from '../../../../../ts/components/flow/elementConfigurations/common/screens';
import { ListenerProvider } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/ListenerProvider';
import { MapElementProvider } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import { MessageActionProvider } from '../contextProviders/MessageActionProvider';
import MessageRouter from './MessageRouter';

const Message = (props) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.message}
            elementType={MAP_ELEMENT_TYPES.message}
        >
            <MapElementModal>
                <MessageActionProvider defaultScreen={screens.message}>
                    <ListenerProvider defaultScreen={screens.message}>
                        <MessageRouter />
                    </ListenerProvider>
                </MessageActionProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Message;
