export const JS_VALID_FILE_TYPES = ['js'];
export const CSS_VALID_FILE_TYPES = ['css'];
export const IMAGE_VALID_FILE_TYPES = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpeg',
    'jfif',
    'pjpeg',
    'pjp',
    'png',
    'svg',
    'webp',
    'bmp',
    'ico',
    'cur',
    'tif',
    'tiff',
];
