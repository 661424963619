import { useEffect, useState } from 'react';
import Select from 'react-select';
import { getAllServices } from '../../../../../../ts/sources/service';
import translations from '../../../../../../ts/translations';
import { isNullOrEmpty } from '../../../../../../ts/utils/guard';
import ButtonDefault from '../../../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../../../ts/components/buttons/ButtonPrimary';
import FormGroup from '../../../../../../ts/components/generic/FormGroup';
import Loader from '../../../../../../ts/components/loader/Loader';
import { useListener } from '../../../../../../ts/components/flow/elementConfigurations/contextProviders/ListenerProvider';
import { useMapElement } from '../../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import ModalBody from '../../../../../../ts/components/generic/modal/ModalBody';
import ModalFooter from '../../../../../../ts/components/generic/modal/ModalFooter';
import ValueSelectorModal from '../../../../../../ts/components/values/selector/ValueSelectorModal';

const ListenerDetails = () => {
    const [services, setServices] = useState([]);
    const [isLoadingServices, setIsLoadingServices] = useState(true);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const { tenantId, mapElement, notifyError, setConfigTitle } = useMapElement();

    const {
        listenerToEdit,
        onSelectDeveloperName,
        onSelectListenerType,
        onSelectServiceElementId,
        onSelectValueReference,
        onApplyListener,
        onReturnToDefaultScreen,
    } = useListener();

    const { container } = useMapElement();

    const { listener, index } = listenerToEdit;

    const isNameValid = !isNullOrEmpty(listener?.developerName);
    const isTypeValid = !isNullOrEmpty(listener?.listenerType);
    const isSelectedServiceElementValid = !isNullOrEmpty(listener?.serviceElementId);
    const isSelectedValueValid = !isNullOrEmpty(listener?.valueElementToReferenceForListeningId);
    const isFormValid =
        isNameValid && isTypeValid && isSelectedServiceElementValid && isSelectedValueValid;

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        (async () => {
            try {
                const response = await getAllServices({ tenantId });

                setServices(response);
                setIsLoadingServices(false);
            } catch (error) {
                notifyError(error);
            }
        })();
    }, []);

    useEffect(() => {
        setConfigTitle(`Listener for: ${mapElement.developerName}`);

        return () => setConfigTitle(null);
    }, [mapElement.developerName, setConfigTitle]);

    const onSave = () => {
        setHasSubmitted(true);

        if (isFormValid) {
            onApplyListener(index);
        }
    };

    const renderBody = () => (
        <>
            <FormGroup
                label="Name"
                htmlFor="listenerName"
                isRequired
                isValid={isNameValid}
                validationMessage="Please enter a name."
                showValidation={hasSubmitted}
            >
                <input
                    id="listenerName"
                    value={listener.developerName ?? ''}
                    onChange={({ target: { value } }) => onSelectDeveloperName(value)}
                    size="25"
                    required
                    className="form-control"
                    type="text"
                />
            </FormGroup>

            <FormGroup
                label="Listen to events of type"
                htmlFor="listenerType"
                isRequired
                isValid={isTypeValid}
                validationMessage="Please enter a type."
                showValidation={hasSubmitted}
            >
                <input
                    id="listenerType"
                    value={listener.listenerType ?? ''}
                    onChange={({ target: { value } }) => onSelectListenerType(value)}
                    size="25"
                    required
                    className="form-control"
                    type="text"
                />
            </FormGroup>

            {isLoadingServices ? (
                <Loader />
            ) : (
                <FormGroup
                    label="The connector implementing this listener"
                    htmlFor="service-select"
                    isRequired
                    isValid={isSelectedServiceElementValid}
                    validationMessage="Please select a connector."
                    showValidation={hasSubmitted}
                >
                    <Select
                        inputId="service-select"
                        className="form-control-width"
                        onChange={(selection) => onSelectServiceElementId(selection.value)}
                        value={{
                            label: services.find((m) => m.id === listener.serviceElementId)
                                ?.developerName,
                            value: listener.serviceElementId || '',
                        }}
                        options={services.map((s) => ({
                            value: s.id,
                            label: s.developerName,
                        }))}
                    />
                </FormGroup>
            )}

            <FormGroup
                label="The Value being listened to"
                isRequired
                isValid={isSelectedValueValid}
                validationMessage="Please select a value."
                showValidation={hasSubmitted}
            >
                <ValueSelectorModal
                    value={listener.valueElementToReferenceForListeningId}
                    onChange={onSelectValueReference}
                    container={container}
                />
            </FormGroup>
        </>
    );

    const renderFooter = () => (
        <>
            <ButtonDefault className="flex-child-right" onClick={onReturnToDefaultScreen}>
                Cancel
            </ButtonDefault>
            <ButtonPrimary className="margin-left" onClick={onSave}>
                {translations.GRAPH_config_panel_add}
            </ButtonPrimary>
        </>
    );

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default ListenerDetails;
