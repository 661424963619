import { useState, createContext, useContext } from 'react';
import { useMapElement } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import screens from '../../../../../ts/components/flow/elementConfigurations/common/screens';

const Context = createContext(undefined);

/**
 * Holds the context of the operator map element configuration
 */
const OperatorProvider = ({ initialOperationIndex = null, children }) => {
    const { mapElement, setMapElement, onSwitchScreen } = useMapElement();

    const [selectedOperationIndex, setSelectedOperationIndex] = useState(initialOperationIndex);

    const onEditOperation = (operationIndex) => {
        setSelectedOperationIndex(operationIndex);
        if (mapElement.operations[operationIndex].macroElementToExecuteId !== null) {
            onSwitchScreen(screens.macroDetails);
        } else {
            onSwitchScreen(screens.operationDetails);
        }
    };

    const onDeleteOperation = (operationIndex) => {
        setMapElement({
            ...mapElement,
            operations: mapElement.operations.filter((_, index) => index !== operationIndex),
        });
    };

    const contextValue = {
        selectedOperationIndex,
        setSelectedOperationIndex,
        onEditOperation,
        onDeleteOperation,
    };

    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

const useOperator = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useOperator must be used within a OperatorProvider');
    }
    return context;
};

export { OperatorProvider, useOperator };
