import MapElementModal from '../../../../../ts/components/graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../../ts/constants';
import { MapElementProvider } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import StepRouter from './StepRouter';

/**
 * Handles switching between the step configuration screens
 */
const Step = (props) => {
    return (
        <MapElementProvider {...props} defaultScreen={'step'} elementType={MAP_ELEMENT_TYPES.step}>
            <MapElementModal>
                <StepRouter />
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Step;
