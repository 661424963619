import { CRUD_OPERATION_TYPES } from '../common/constants';
import screens from '../../../../../ts/components/flow/elementConfigurations/common/screens';
import { DataActionProvider } from '../contextProviders/DataActionProvider';
import { MapElementProvider } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import { ListenerProvider } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/ListenerProvider';
import SaveRouter from './SaveRouter';
import MapElementModal from '../../../../../ts/components/graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../../ts/constants';

const Save = (props) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.save}
            elementType={MAP_ELEMENT_TYPES.databaseSave}
        >
            <MapElementModal>
                <DataActionProvider
                    defaultScreen={screens.save}
                    crudOperationType={CRUD_OPERATION_TYPES.save}
                >
                    <ListenerProvider defaultScreen={screens.save}>
                        <SaveRouter />
                    </ListenerProvider>
                </DataActionProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Save;
