import translations from '../../../../translations';
import FormGroup from '../../../generic/FormGroup';

type Props = {
    // FormGroup props
    isValid: boolean;
    showValidation: boolean;
    // input props
    id: string;
    name: string;
    onUpdateName: (name: string) => void;
};

const NameInput = ({ isValid, showValidation, id, name, onUpdateName }: Props) => (
    <FormGroup
        label={translations.MAP_ELEMENT_name_field_label}
        htmlFor={id}
        isRequired
        isValid={isValid}
        validationMessage={translations.MAP_ELEMENT_name_field_validation_message}
        showValidation={showValidation}
    >
        <input
            autoFocus
            id={id}
            data-testid={id}
            value={name}
            onChange={(e) => onUpdateName(e.target.value)}
            maxLength={255}
            required
            className="form-control form-control-width"
            type="text"
        />
    </FormGroup>
);

export default NameInput;
