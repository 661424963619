import { useEffect, useState } from 'react';
import ButtonDefault from '../../../../../ts/components/buttons/ButtonDefault';
import ButtonPrimary from '../../../../../ts/components/buttons/ButtonPrimary';
import FormGroup from '../../../../../ts/components/generic/FormGroup';
import Loader from '../../../../../ts/components/loader/Loader';
import screens from '../../../../../ts/components/flow/elementConfigurations/common/screens';
import { useMapElement } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import { useOperator } from './OperatorProvider';
import ModalFooter from '../../../../../ts/components/generic/modal/ModalFooter';
import ModalBody from '../../../../../ts/components/generic/modal/ModalBody';
import { macroSource } from '../../../../../ts/sources/macro';

const MacroDetails = () => {
    const { mapElement, onSwitchScreen, notifyError, setConfigTitle } = useMapElement();

    const { selectedOperationIndex, setSelectedOperationIndex } = useOperator();

    const [macros, setMacros] = useState([]);
    const [isLoadingMacros, setIsLoadingMacros] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [selectedMacroId, setSelectedMacroId] = useState(
        mapElement.operations?.[selectedOperationIndex]?.macroElementToExecuteId ?? null,
    );

    const isValid = selectedMacroId !== null;

    const onSave = () => {
        setHasSubmitted(true);
        if (isValid) {
            const index = selectedOperationIndex ?? mapElement.operations.length;
            const operation = {
                macroElementToExecuteId: selectedMacroId,
                macroElementToExecuteDeveloperName: macros.find(
                    (macro) => macro.id === selectedMacroId,
                ).developerName,
            };

            mapElement.operations[index] = operation;

            onSwitchScreen(screens.operator);
        }
    };

    const onCancel = () => {
        onSwitchScreen(screens.operator);
        setSelectedOperationIndex(null);
    };

    const getMacros = async () => {
        try {
            const response = await macroSource.getAll();

            setMacros(response);
        } catch (error) {
            notifyError(error);
        }
    };

    const renderBody = () => {
        if (isLoadingMacros) {
            return <Loader message="Loading Macros" />;
        }

        return (
            <FormGroup
                label="Which Macro do you want to run?"
                isRequired
                isValid={isValid}
                validationMessage="Please select a macro to run."
                showValidation={hasSubmitted}
                htmlFor="macro-select"
            >
                <select
                    id="macro-select"
                    data-testid="macro-select"
                    className="form-control form-select form-control-width"
                    value={selectedMacroId ?? ''}
                    onChange={({ target: { value } }) => setSelectedMacroId(value)}
                >
                    {selectedMacroId === null && <option value="">Select a Macro</option>}
                    {macros.map((macro) => (
                        <option value={macro.id} key={macro.id}>
                            {macro.developerName}
                        </option>
                    ))}
                </select>
            </FormGroup>
        );
    };

    useEffect(() => {
        setConfigTitle(`Macro for: ${mapElement.developerName}`);

        return () => setConfigTitle(null);
    }, [mapElement.developerName, setConfigTitle]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setIsLoadingMacros(true);
        (async () => {
            await getMacros();
            setIsLoadingMacros(false);
        })();
    }, []);

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>
                <ButtonDefault className="flex-child-right" onClick={onCancel}>
                    Cancel
                </ButtonDefault>
                <ButtonPrimary className="margin-left" onClick={onSave}>
                    {selectedOperationIndex === null ? 'Add' : 'Update'}
                </ButtonPrimary>
            </ModalFooter>
        </>
    );
};

export default MacroDetails;
