import { useOperator } from './OperatorProvider';
import { OPERATION_COMMANDS } from '../../../../../ts/components/flow/elementConfigurations/operator/constants';
import { useMapElement } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import DraggableTable from '../../../../../ts/components/generic/DraggableTable';
import translations from '../../../../../ts/translations';
import { Trash } from '@phosphor-icons/react';

const OperationsList = () => {
    const { mapElement, setMapElement } = useMapElement();
    const { onEditOperation, onDeleteOperation } = useOperator();

    // returns one of OPERATION_COMMANDS based on supplied command string and contentType
    // we need content type because some command strings are used for multiple types but behave in different ways
    const findOperationCommand = (command, contentType = null) => {
        if (command === null) {
            return null;
        }

        return Object.values(OPERATION_COMMANDS).find(
            (opCommand) =>
                opCommand.command === command &&
                (contentType === null ||
                    opCommand.contentTypes.includes(contentType.toUpperCase())),
        );
    };

    const onReorderOperation = (orderedOperations) => {
        setMapElement({
            ...mapElement,
            operations: orderedOperations,
        });
    };

    // Renders the content of a marco description
    const generateMacroDescription = (operation) => {
        return (
            <span>
                Execute Macro:{' '}
                <span className="strong">{operation.macroElementToExecuteDeveloperName ?? ''}</span>
            </span>
        );
    };

    // Renders the content of a value with possible nested property
    const generateValueDescription = (valueName, propertyName) => {
        return (
            <>
                <span className="strong">{valueName ?? ''}</span>
                {propertyName && (
                    <>
                        {' / '}
                        <span className="strong">{propertyName}</span>
                    </>
                )}
            </>
        );
    };

    // Renders the content of an operations description
    const generateOperationDescription = (operation) => {
        const {
            valueElementToApplyId,
            valueElementToApplyDeveloperName,
            valueElementToApplyContentType,
            valueElementToApplyPropertyContentType,
            valueElementToApplyPropertyDeveloperName,
            valueElementToApplySortByDeveloperName,
            valueElementToApplySortDirection,
            valueElementToReferenceId,
            valueElementToReferenceDeveloperName,
            valueElementToReferencePropertyDeveloperName,
            valueElementToReferenceRelativeId,
            valueElementToReferenceRelativeDeveloperName,
            valueElementToReferenceRelativePropertyDeveloperName,
        } = operation;

        // Finds applicable apply command object
        const applyCommand = findOperationCommand(
            valueElementToApplyId?.command ?? null,
            valueElementToApplyPropertyContentType ?? valueElementToApplyContentType,
        );

        // Finds applicable reference command object and lowercase its display property
        const accessorCommandDisplay = (
            findOperationCommand(valueElementToReferenceId?.command ?? null)?.display ?? ''
        ).toLowerCase();

        const relativeCommand = (
            findOperationCommand(valueElementToReferenceRelativeId?.command ?? null)?.command ?? ''
        ).toLowerCase();

        switch (applyCommand) {
            case OPERATION_COMMANDS.setEqual:
                return (
                    <span>
                        {'Set '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                        {' equal to the '}
                        {accessorCommandDisplay}{' '}
                        {generateValueDescription(
                            valueElementToReferenceDeveloperName,
                            valueElementToReferencePropertyDeveloperName,
                        )}
                    </span>
                );

            case OPERATION_COMMANDS.add:
                return (
                    <span>
                        {'Add the value of '}
                        {generateValueDescription(
                            valueElementToReferenceDeveloperName,
                            valueElementToReferencePropertyDeveloperName,
                        )}
                        {' to the '}
                        {accessorCommandDisplay}{' '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                    </span>
                );

            case OPERATION_COMMANDS.subtract:
                return (
                    <span>
                        {'Subtract the value of '}
                        {generateValueDescription(
                            valueElementToReferenceDeveloperName,
                            valueElementToReferencePropertyDeveloperName,
                        )}
                        {' from the '}
                        {accessorCommandDisplay}{' '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                    </span>
                );

            case OPERATION_COMMANDS.divide:
                return (
                    <span>
                        {'Divide the value of '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                        {' by the '}
                        {accessorCommandDisplay}{' '}
                        {generateValueDescription(
                            valueElementToReferenceDeveloperName,
                            valueElementToReferencePropertyDeveloperName,
                        )}
                    </span>
                );

            case OPERATION_COMMANDS.multiply:
                return (
                    <span>
                        {'Multiply the value of '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                        {' by the '}
                        {accessorCommandDisplay}{' '}
                        {generateValueDescription(
                            valueElementToReferenceDeveloperName,
                            valueElementToReferencePropertyDeveloperName,
                        )}
                    </span>
                );

            case OPERATION_COMMANDS.modulo:
                return (
                    <span>
                        {'Set the value of '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                        {' to the value of '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                        {' modulo the '}
                        {accessorCommandDisplay}{' '}
                        {generateValueDescription(
                            valueElementToReferenceDeveloperName,
                            valueElementToReferencePropertyDeveloperName,
                        )}
                    </span>
                );

            case OPERATION_COMMANDS.join:
                return (
                    <span>
                        {'Join the value of '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                        {' with the '}
                        {accessorCommandDisplay}{' '}
                        {generateValueDescription(
                            valueElementToReferenceDeveloperName,
                            valueElementToReferencePropertyDeveloperName,
                        )}
                    </span>
                );

            case OPERATION_COMMANDS.sort:
                return (
                    <span>
                        {'Sort '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                        {' by '}
                        <span className="strong">
                            {valueElementToApplySortByDeveloperName ?? ''}
                        </span>
                        {' in '}
                        {valueElementToApplySortDirection === 'ASCENDING'
                            ? 'ascending'
                            : 'descending'}
                        {' order'}
                    </span>
                );

            case OPERATION_COMMANDS.update:
                return (
                    <span>
                        {'Update '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                        {' with the '}
                        {accessorCommandDisplay}{' '}
                        {generateValueDescription(
                            valueElementToReferenceDeveloperName,
                            valueElementToReferencePropertyDeveloperName,
                        )}
                    </span>
                );

            case OPERATION_COMMANDS.remove:
                return (
                    <span>
                        {'Remove '}
                        {generateValueDescription(
                            valueElementToReferenceDeveloperName,
                            valueElementToReferencePropertyDeveloperName,
                        )}
                        {' from '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                    </span>
                );

            case OPERATION_COMMANDS.empty:
                return (
                    <span>
                        {'Empty '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                    </span>
                );

            case OPERATION_COMMANDS.new:
                return (
                    <span>
                        {'Create new '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                    </span>
                );

            case OPERATION_COMMANDS.setRelativeDate:
                return (
                    <span>
                        {'Set the value of '}
                        {generateValueDescription(
                            valueElementToApplyDeveloperName,
                            valueElementToApplyPropertyDeveloperName,
                        )}
                        {' to the value of '}
                        {generateValueDescription(
                            valueElementToReferenceDeveloperName,
                            valueElementToReferencePropertyDeveloperName,
                        )}{' '}
                        {relativeCommand}{' '}
                        {generateValueDescription(
                            valueElementToReferenceRelativeDeveloperName,
                            valueElementToReferenceRelativePropertyDeveloperName,
                        )}{' '}
                        {valueElementToReferenceRelativeId?.relativeUnit}
                    </span>
                );

            default:
                return 'Unknown Operation';
        }
    };

    const generateDescription = (operation) => {
        if (operation.macroElementToExecuteId !== null) {
            return generateMacroDescription(operation);
        }

        return generateOperationDescription(operation);
    };

    const operationsTableColumns = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item, rowIndex }) => (
                <button
                    className="link-emulate"
                    title={translations.COMMON_edit}
                    onClick={() => onEditOperation(rowIndex)}
                    type="button"
                >
                    {generateDescription(item) ?? ''}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ rowIndex }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={translations.COMMON_delete}
                        className="table-icon table-icon-delete margin-left"
                        aria-label={translations.COMMON_delete}
                        onClick={() => onDeleteOperation(rowIndex)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '7.5rem',
        },
    ];

    return (
        <DraggableTable
            columns={operationsTableColumns}
            wrapperClassName="margin-bottom-large"
            items={mapElement.operations ?? []}
            testId="operations-list"
            tableId="operations-list"
            onReorder={onReorderOperation}
        />
    );
};

export default OperationsList;
