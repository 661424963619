import { pathOr } from 'ramda';
import { useState } from 'react';
import translations from '../../../../../../../ts/translations';
import { isNullOrEmpty } from '../../../../../../../ts/utils/guard';
import Breadcrumb from '../../../../../../../ts/components/Breadcrumb';
import { useDataAction } from '../../../contextProviders/DataActionProvider';
import { useMapElement } from '../../../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import Footer from '../../Footer';
import FilterById from './FilterById';
import FilterByWhereClause from './FIlterByWhereClause';
import FilterTypeDropdown from './FilterTypeDropdown';
import ModalFooter from '../../../../../../../ts/components/generic/modal/ModalFooter';
import ModalBody from '../../../../../../../ts/components/generic/modal/ModalBody';

const DataActionFilter = () => {
    const [hasSubmitted, updateHasSubmitted] = useState(false);
    const { breadcrumbs } = useMapElement();
    const { onReturnToDataActionScreen, onApplyDataActionFilter, filterToEdit, onSetFilterType } =
        useDataAction();

    const checkRequiredFields = () => {
        const requiredFields = {};

        const shouldFilterById = filterToEdit?.filterId;
        const shouldFilterByWhereClause = filterToEdit?.where && !filterToEdit?.filterId;

        if (shouldFilterById) {
            requiredFields.valueIdentifier = pathOr('', ['filterId', 'id'], filterToEdit);
        }

        if (shouldFilterByWhereClause) {
            requiredFields.comparisonType = pathOr('', ['comparisonType'], filterToEdit);
        }

        return requiredFields;
    };

    const onSave = () => {
        updateHasSubmitted(true);
        const isFormValid = Object.values(checkRequiredFields()).every(
            (field) => !isNullOrEmpty(field),
        );

        if (isFormValid) {
            onApplyDataActionFilter();
        }
    };

    const onChangeFilterType = (filterType) => {
        // Clears form validation errors
        if (hasSubmitted) {
            updateHasSubmitted(false);
        }
        onSetFilterType(filterType);
    };

    const renderBody = () => {
        const { valueIdentifier, comparisonType } = checkRequiredFields();

        return (
            <>
                <div className="margin-bottom">
                    <Breadcrumb trail={breadcrumbs.trail} activeItemId={breadcrumbs.activeItemId} />
                </div>
                <FilterTypeDropdown onChange={onChangeFilterType} />
                <FilterById idToFilterBy={valueIdentifier} hasSubmitted={hasSubmitted} />
                <FilterByWhereClause comparisonType={comparisonType} hasSubmitted={hasSubmitted} />
            </>
        );
    };

    const renderFooter = () => {
        return (
            <Footer
                save={onSave}
                saveButtonText={translations.DATA_ACTION_save_filter_button_text}
                cancel={onReturnToDataActionScreen}
            />
        );
    };

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default DataActionFilter;
