import OperatorRouter from './OperatorRouter';
import { OperatorProvider } from './OperatorProvider';
import { MapElementProvider } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import screens from '../../../../../ts/components/flow/elementConfigurations/common/screens';
import MapElementModal from '../../../../../ts/components/graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../../ts/constants';

const Operator = (props) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.operator}
            elementType={MAP_ELEMENT_TYPES.operator}
        >
            <MapElementModal>
                <OperatorProvider>
                    <OperatorRouter />
                </OperatorProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Operator;
