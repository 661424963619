import ButtonPrimary from '../../../../../../ts/components/buttons/ButtonPrimary';
import Glyphicon from '../../../../../../ts/components/generic/Glyphicon';

import { useMapElement } from '../../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import { useMessageAction } from '../../contextProviders/MessageActionProvider';
import translations from '../../../../../../ts/translations';
import { Trash } from '@phosphor-icons/react';
import Table from '../../../../../../ts/components/generic/Table';

const MessageActionsList = () => {
    const { mapElement } = useMapElement();

    const { onCreateMessageAction, onEditMessageAction, onDeleteMessageAction } =
        useMessageAction();

    const columns = [
        {
            renderHeader: () => translations.COMMON_TABLE_name,
            renderCell: ({ item, rowIndex }) => (
                <button
                    className="link-emulate"
                    title={`Edit ${item.developerName}`}
                    onClick={() => onEditMessageAction(item, rowIndex)}
                    type="button"
                >
                    {item.developerName ?? ''}
                </button>
            ),
        },
        {
            renderHeader: () => translations.COMMON_TABLE_order,
            renderCell: ({ item: { order } }) => order,
            size: '5rem',
        },
        {
            renderHeader: () => translations.COMMON_TABLE_actions,
            renderCell: ({ item, rowIndex }) => (
                <div className="action-btn-wrapper">
                    <button
                        title={`Delete ${item.developerName}`}
                        className="table-icon table-icon-delete margin-left"
                        aria-label={`Delete ${item.developerName}`}
                        onClick={() => onDeleteMessageAction(rowIndex)}
                        type="button"
                    >
                        <Trash />
                    </button>
                </div>
            ),
            size: '5rem',
        },
    ];

    return (
        <>
            <h4>Message Actions</h4>
            <div className="margin-bottom flex">
                <ButtonPrimary onClick={onCreateMessageAction}>
                    <Glyphicon glyph="plus" />
                    Add Message Action
                </ButtonPrimary>
            </div>
            <Table
                columns={columns}
                wrapperClassName="margin-bottom-large"
                items={mapElement.messageActions ?? []}
                testId="message-action-list"
                tableId="message-action-list"
            />
        </>
    );
};

export default MessageActionsList;
