import screens from '../../../../../ts/components/flow/elementConfigurations/common/screens';
import { MapElementProvider } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import { ListenerProvider } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/ListenerProvider';
import { MessageActionProvider } from '../contextProviders/MessageActionProvider';
import PageRouter from './PageRouter';
import { WaitProvider } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/WaitProvider';
import MapElementModal from '../../../../../ts/components/graph/MapElementModal';
import { MAP_ELEMENT_TYPES } from '../../../../../ts/constants';

const Modal = (props) => {
    return (
        <MapElementProvider
            {...props}
            defaultScreen={screens.page}
            elementType={MAP_ELEMENT_TYPES.modal}
        >
            <MapElementModal>
                <MessageActionProvider defaultScreen={screens.page}>
                    <ListenerProvider defaultScreen={screens.page}>
                        <WaitProvider defaultScreen={screens.page}>
                            <PageRouter />
                        </WaitProvider>
                    </ListenerProvider>
                </MessageActionProvider>
            </MapElementModal>
        </MapElementProvider>
    );
};

export default Modal;
