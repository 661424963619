import { pathOr } from 'ramda';
import { useState } from 'react';
import translations from '../../../../../../ts/translations';
import Breadcrumb from '../../../../../../ts/components/Breadcrumb';
import FormGroup from '../../../../../../ts/components/generic/FormGroup';
import Toggle from '../../../../../../ts/components/inputs/Toggle';
import { useDataAction } from '../../contextProviders/DataActionProvider';
import { useMapElement } from '../../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import { DATA_ACTION_VALUE_SELECTION_LABEL } from '../constants';
import Footer from '../Footer';
import TypeBindingDropdown from '../TypeBindingDropdown';
import FilterButton from './FilterButton';
import SmartSaveToggle from './SmartSaveToggle';
import ModalBody from '../../../../../../ts/components/generic/modal/ModalBody';
import ModalFooter from '../../../../../../ts/components/generic/modal/ModalFooter';
import ValueSelectorModal from '../../../../../../ts/components/values/selector/ValueSelectorModal';
import { isNullOrEmpty } from '../../../../../../ts/utils/guard';

const DataActionConfiguration = () => {
    const [hasSubmitted, updateHasSubmitted] = useState(false);
    const { breadcrumbs, tenantId } = useMapElement();

    const {
        dataActionToEdit,
        onUpdateDataActionName,
        onUpdateDataActionBinding,
        onSetDataActionLoadValue,
        onApplyDataAction,
        onReturnToDefaultScreen,
        onUpdateDataActionOrder,
        onUpdateDataActionDisabled,
    } = useDataAction();

    const { dataAction, index } = dataActionToEdit;

    const typeElementId = dataAction.objectDataRequest?.typeElementId;

    const checkRequiredFields = () => ({
        name: pathOr('', ['developerName'], dataAction),
        order: pathOr('', ['order'], dataAction),
        loadValue: pathOr('', ['valueElementToApplyId', 'id'], dataAction),
        dataSource: pathOr('', ['objectDataRequest', 'typeElementBindingId'], dataAction),
    });

    const onSave = () => {
        updateHasSubmitted(true);
        const isFormValid = Object.values(checkRequiredFields()).every(
            (field) => !isNullOrEmpty(field),
        );

        if (isFormValid) {
            onApplyDataAction(index);
        }
    };

    const renderBody = () => {
        const { name, order, loadValue, dataSource } = checkRequiredFields();
        return (
            <>
                <div className="margin-bottom">
                    <Breadcrumb trail={breadcrumbs.trail} activeItemId={breadcrumbs.activeItemId} />
                </div>
                <FormGroup
                    label="Name"
                    htmlFor="data-action-name"
                    isRequired
                    validationMessage={translations.MAP_ELEMENT_name_field_validation_message}
                    isValid={!isNullOrEmpty(name)}
                    showValidation={hasSubmitted}
                >
                    <input
                        id="data-action-name"
                        defaultValue={name}
                        onChange={(e) => onUpdateDataActionName(e.target.value)}
                        maxLength="255"
                        required
                        className="form-control form-control-width"
                        type="text"
                    />
                </FormGroup>
                <FormGroup
                    label="The order in which this Data Action should be loaded"
                    htmlFor="data-action-order"
                    isRequired
                    validationMessage={translations.MAP_ELEMENT_order_field_validation_message}
                    isValid={!isNullOrEmpty(order)}
                    showValidation={hasSubmitted}
                >
                    <input
                        id="data-action-order"
                        defaultValue={order}
                        onChange={(e) => onUpdateDataActionOrder(e.target.value)}
                        maxLength="255"
                        required
                        className="form-control form-control-width"
                        type="number"
                    />
                </FormGroup>
                <div className="form-group">
                    <label htmlFor="disable-data-action-toggle">
                        <Toggle
                            id="disable-data-action-toggle"
                            isOn={dataAction.disabled}
                            onChange={({ isOn }) => onUpdateDataActionDisabled({ isOn })}
                            testId="is-fixed"
                        />
                        Disable this data action
                    </label>
                </div>
                <SmartSaveToggle />
                <FormGroup
                    isRequired
                    label={DATA_ACTION_VALUE_SELECTION_LABEL[dataAction.crudOperationType]}
                    validationMessage={translations.MAP_ELEMENT_value_field_validation_message}
                    isValid={!isNullOrEmpty(loadValue)}
                    showValidation={hasSubmitted}
                >
                    <ValueSelectorModal
                        value={dataAction.valueElementToApplyId}
                        onChangeAsValueReference={onSetDataActionLoadValue}
                        includeSystemValues={false}
                    />
                </FormGroup>
                {dataAction.valueElementToApplyId.id ? (
                    <>
                        <TypeBindingDropdown
                            typeElementId={typeElementId}
                            typeElementBindingId={dataSource}
                            tenantId={tenantId}
                            onSelect={onUpdateDataActionBinding}
                            validationMessage={
                                translations.MAP_ELEMENT_value_field_validation_message
                            }
                            isValid={!isNullOrEmpty(dataSource)}
                            showValidation={hasSubmitted}
                            crudOperationType={dataAction.crudOperationType}
                        />
                        <FilterButton typeElementId={typeElementId} />
                    </>
                ) : null}
            </>
        );
    };

    const renderFooter = () => {
        return (
            <Footer
                save={onSave}
                saveButtonText={translations.GRAPH_config_panel_add}
                cancel={onReturnToDefaultScreen}
            />
        );
    };

    return (
        <>
            <ModalBody>{renderBody()}</ModalBody>
            <ModalFooter>{renderFooter()}</ModalFooter>
        </>
    );
};

export default DataActionConfiguration;
