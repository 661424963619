import { useMapElement } from '../../../../../ts/components/flow/elementConfigurations/contextProviders/MapElementProvider';
import OutcomeDetails from '../../../../../ts/components/flow/elementConfigurations/common/OutcomeDetails';
import screens from '../../../../../ts/components/flow/elementConfigurations/common/screens';
import SubFlowConfiguration from './SubFlowConfiguration';
import ScreenRouter from '../../../../../ts/components/generic/ScreenRouter';

/**
 * Handles switching between the subflow configuration screens
 */
const SubFlowRouter = () => {
    const {
        currentScreen,
        mapElement,
        selectedOutcomeIndex,
        setSelectedOutcomeIndex,
        onSwitchScreen,
    } = useMapElement();

    return (
        <ScreenRouter currentScreen={currentScreen}>
            <SubFlowConfiguration screen={screens.subflow} />
            <OutcomeDetails
                screen={screens.outcome}
                outcome={mapElement?.outcomes?.[selectedOutcomeIndex]}
                onSave={() => onSwitchScreen(screens.subflow)}
                onCancel={() => {
                    onSwitchScreen(screens.subflow);
                    setSelectedOutcomeIndex(null);
                }}
            />
        </ScreenRouter>
    );
};

export default SubFlowRouter;
